/* eslint-disable react/prop-types */
import React from 'react';

import Athletes from '@content/Athletes';
import SiteHead from '@mainComponents/SiteHead';

const AthletesSite = pageContext => <Athletes pageContext={pageContext} />;

export const Head = ({location}) => <SiteHead location={location} />;

export default AthletesSite;