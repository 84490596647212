import React from 'react';

import Container from '@components/Container';
import Wrapper from '@components/layout/Wrapper';
import useGetNations from '@hooks/useGetNations';
import Layout from '@mainComponents/Layout';
import PropTypes from 'prop-types';

import NationAthletes from './components/NationAthletes';

const Athletes = ({ pageContext: {uri} }) => {
	const {nations} = useGetNations();
	return (
		<Layout
			headerType='page-without-image'
			showHeader
			path={uri}
		>
			<Wrapper>
				<Container>
					{nations.map(nation => <NationAthletes nation={nation} key={nation.remoteId} />)}
				</Container>
			</Wrapper>
		</Layout>
	);
};

Athletes.propTypes = {
	pageContext: PropTypes.object.isRequired
};

export default Athletes;