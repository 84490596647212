import React from 'react';

import Button from '@components/buttons/Button';
import FlexBox from '@components/layout/FlexBox';
import Divider from '@components/surfaces/Divider';
import HeadlineDivider from '@components/surfaces/HeadlineDivider';
import useGetColors from '@hooks/useGetSiteColors';
import settings from '@settings';
import { navigate } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';

const NationAthletes = ({nation}) => {
	const {secondary} = useGetColors();

	const headlineContent = () => (
		<FlexBox direction='row' align="center" gap={settings.sizes.medium}>
			<GatsbyImage image={getImage(nation.flag)} alt={nation.name} />
			<h3>
				{nation.name}
			</h3>
		</FlexBox>
	);

	return (
		<>
			<HeadlineDivider content={headlineContent} />
			<FlexBox direction='column' hasFullWidth styles={{marginBottom: settings.sizes.medium}}>
				{nation.athletes.sort((a,b) => a.name.toLowerCase() - b.name.toLowerCase()).map((athlete, index, array) =>
					<React.Fragment key={athlete.remoteId}>
						<FlexBox
							direction='row'
							align='center'
							justify='space-between'
							hasFullWidth
							styles={{marginTop: settings.sizes.medium, marginBottom: index+1 === array.length ? settings.sizes.large : 0, paddingLeft: settings.sizes.large, paddingRight: settings.sizes.large }}
						>
							<h4 css={{fontWeight: 600, marginBottom: 0 }}>{athlete.name}</h4>
							<Button
								color={settings.colors.dark}
								hoverColor={secondary}
								text='View Profile >'
								size='small'
								onClick={() => navigate(`/athletes/${settings.functions.slugify(athlete.name)}`)}
							/>
						</FlexBox>
						{index+1 !== array.length &&
							<Divider gap='small' />
						}
					</React.Fragment>
				)}
			</FlexBox>
		</>
	);
};

NationAthletes.propTypes = {
	nation: PropTypes.object.isRequired
};

export default NationAthletes;