import React from 'react';

import settings from '@settings';
import PropTypes from 'prop-types';

const HeadlineDivider= ({title, content}) => (
	<div css={{backgroundColor: settings.colors.white, padding: settings.padding.medium, border: `1px solid ${settings.colors.dark}`}}>
		{title && 
			<h3>
				{title}
			</h3>
		}
		{content && content()}
	</div>
);

HeadlineDivider.propTypes = {
	title: PropTypes.string,
	content: PropTypes.func
};

HeadlineDivider.defaultProps = {
	title: '',
	content: () => {}
};

export default HeadlineDivider;
